@import "/src/styles/index.scss";

.board-wrapper {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    overflow-x: scroll;
    // flex-wrap: nowrap;
    .board-columns {
      max-width: 19.33333%;
      flex: 0 0 19.33333%;
      background-color: $red-color;
      height: calc(100vh - 135px);
      background-color: $bg-columns;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      overflow: hidden;
      .columns-header {
        height: 50px;
        padding: 12px 9px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .columns-header-left {
          display: flex;
          align-items: center;
      
          .columns-header-title {
            font: normal normal bold 16px/24px SVN-Merge;
            letter-spacing: 0.14px;
            color: $white-color;
            font-weight: 800;
            padding: 0 10px;

          }
          .columns-header-count {
            width: 20px;
            height: 20px;
            display: inline-block;
            text-align: center;
            line-height: 20px;
            background-color: $white-color;
            font-size: 14px;
            color: $black-color;
            border-radius: 100%;
            font-weight: 700;
          }
        }
        .colums-header-right{
          .btn-print-order{
            border: 1px solid #FFFFFF;
            border-radius: 6px;
            padding: 3px 10px;
            // width: 73px;
            // height: 25px;
            font-size: 14px;
            color: $white-color;
            background: transparent;
          }
        }
      }
      &:nth-child(1) .columns-header {
        background-color: $yallow-color;
      }
      &:nth-child(2) .columns-header {
        background-color: $green-color-1;
      }
      &:nth-child(3) .columns-header {
        background-color: $orange-color;
      }
      &:nth-child(4) .columns-header {
        background-color: $green-color-3;
      }
      &:nth-child(5) .columns-header {
        background-color: $green-color-2;
      }
      .colums-wrapper {
        padding: 11px 8px;
        height: 100%;
        overflow-y: auto;
        padding-bottom: 50px;
        &::-webkit-scrollbar {
          width: 0px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          background-color: transparent;
          background-clip: content-box;
        }
   
      }
    }
  }
  
  .loading_container{
    position:absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    justify-content:center;
    align-items: center;
    background: rgba(255,255,255,0.4);
  }